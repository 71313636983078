import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './ChatRoom.css';
import { useNavigate } from 'react-router-dom';

const socket = io("https://wasomihuru.co.tz", {
  transports: ["polling"],
  path: "/socket.io/"
});

/*const socket = io( '/', {
  withCredentials: true, // Ensure cookies are sent with requests
  transports: ['websocket', 'polling'],  // Ensure compatibility with Heroku
});
*/

socket.on('connect', () => {
  console.log('Connected to Socket.IO server');
});

socket.on('disconnect', () => {
  console.log('Disconnected from Socket.IO server');
});

const ChatRoom = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    socket.on('message', (msg) => {
      console.log('Received message from backend:', msg);
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    return () => {
      socket.off('message');
      socket.off('error');
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      console.log('Sending message to backend:', message);
      socket.emit('send_message', { message });
      setMessage('');
    }
  };

  return (
    <div className="chat-room">
      <div className='text-center'><h2>Community ChatRoom</h2></div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className="chat-message">
            <strong>{msg.user.role === 'admin' ? 'Admin' : msg.user.username}:</strong>{' '}
            {msg.msg} <span className="timestamp">{msg.timestamp}</span>
          </div>
        ))}
      </div>
      
      <form onSubmit={sendMessage} className="chat-form">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="chat-input"
        />
        <button type="submit" className="send-button">Send</button>
      </form>
        <p className = "my-3">Please refresh page in case your ChatRoom is not displaying messages.  </p>
    </div>
  );
};

export default ChatRoom;