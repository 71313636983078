import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css'; // Import the styles

const SearchBar = ({ options }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleSelect = async (selectedTitle) => {
    setSearchTerm('');
    setShowDropdown(false);

    try {
      // Fetch the course ID from the backend using the selected title
      const response = await fetch(`/api/course-id?title=${encodeURIComponent(selectedTitle)}`);
      //const response = await fetch(`http://localhost:5000/api/course-id?title=${encodeURIComponent(selectedTitle)}`);
      const data = await response.json();

      if (data.id) {
        // Navigate to the course page using the course ID
        navigate(`/courses/${data.id}`);
      } else {
        // Handle case where the course is not found
        alert('Course not found.');
      }
    } catch (error) {
      console.error('Error fetching course ID:', error);
    }
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        className="form-control"
        placeholder="Search for Course"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {showDropdown && (
        <ul className="dropdown-menu show">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))
          ) : (
            <li className="dropdown-item">No results found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
