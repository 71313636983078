import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ManageCourses.css';

const ManageCourses = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [showReplacePopup, setShowReplacePopup] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('/api/courses');
        //const response = await fetch('http://127.0.0.1:5000/api/courses');
        const data = await response.json();
        setCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleDelete = async (courseId) => {
    try {
      //const response = await fetch(`http://127.0.0.1:5000/api/courses/${courseId}`, {
      const response = await fetch(`/api/courses/${courseId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setCourses(courses.filter(course => course.id !== courseId));
        console.log('Course deleted:', courseId);
      } else {
        console.error('Failed to delete course');
      }
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUploadVideo = (courseId) => {
    const course = courses.find(course => course.id === courseId);

    if (course.video_path) {
      // If the course already has a video, prompt for confirmation
      setSelectedCourseId(courseId);
      setShowReplacePopup(true);
    } else {
      // No existing video, proceed with upload
      uploadVideo(courseId);
    }
  };

  const confirmReplaceVideo = () => {
    uploadVideo(selectedCourseId);
    setShowReplacePopup(false);
    setSelectedCourseId(null);
  };

  const uploadVideo = async (courseId) => {
    const formData = new FormData();
    formData.append('video', videoFile);

    try {
      //const response = await fetch(`http://127.0.0.1:5000/api/courses/${courseId}/video`
      const response = await fetch(`/api/courses/${courseId}/video`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const updatedCourse = await response.json();
        const updatedCourses = courses.map(course =>
          course.id === courseId ? { ...course, video_path: updatedCourse.video_path } : course
        );
        setCourses(updatedCourses);
        console.log('Video uploaded successfully');
      } else {
        console.error('Failed to upload video');
      }
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };

  const cancelReplaceVideo = () => {
    setShowReplacePopup(false);
    setSelectedCourseId(null);
  };

  return (
    <div className="manage-courses">
      <h2>Manage Courses</h2>
      {courses.length === 0 ? (
        <p>No courses available.</p>
      ) : (
        <ul>
          {courses.map(course => (
            <li key={course.id} className="course-item">
              <div className="course-info">
                <h4>{course.title}</h4>
                <p>{course.description}</p>
                <p><strong>Class:</strong> {course.course_class}</p>
                <p><strong>Subject:</strong> {course.course_subject}</p>
                <p><strong>Video:</strong> {course.video_path ? 'Video Uploaded' : 'No Video Uploaded'}</p>
              </div>
              <div className="course-actions">
                <Link to={`/admin/edit-course/${course.id}`} className="btn btn-secondary">Edit</Link>
                <button onClick={() => handleDelete(course.id)} className="btn btn-danger">Delete</button>
              </div>
              <div className="video-upload">
                <input type="file" accept="video/*" onChange={handleVideoChange} />
                <button onClick={() => handleUploadVideo(course.id)} className="btn btn-primary">Upload Video</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {showReplacePopup && (
        <div className="replace-popup">
          <p>A video already exists for this course. Do you want to replace it?</p>
          <button onClick={confirmReplaceVideo} className="btn btn-warning">Replace Video</button>
          <button onClick={cancelReplaceVideo} className="btn btn-secondary">Cancel</button>
        </div>
      )}
    </div>
  );
};

export default ManageCourses;
