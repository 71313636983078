// src/components/CallToActionSection.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CallToActionSection.css'; // Import the styles

const CallToActionSection = () => {
  const navigate = useNavigate();

  const handleBrowseCourses = () => {
    navigate('/browse-courses');
  };

  return (
    <div className="call-to-action-section">
      <div className="container text-center">
        <h2 className="cta-title">Unlock Your Potential with Our Courses</h2>
        <p className="cta-text">
          Join thousands of students who are advancing their careers with our top-notch online courses.
          Explore a variety of topics and find the right course for you.
        </p>
        <button onClick={handleBrowseCourses} className="btn btn-secondary cta-button">Browse Courses</button>
      </div>
    </div>
  );
};

export default CallToActionSection;
