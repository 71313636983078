import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode} from 'jwt-decode'; // Correct import
import Modal from 'react-bootstrap/Modal';

// HOC to protect routes based on user role
const withAdminProtection = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false); // State to manage Modal visibility

    useEffect(() => {
      const checkAuth = () => {
        // Get the JWT from cookies
        const token = Cookies.get('access_token');
        console.log('Token:', token);

        if (!token) {
          // No token found, redirect to login or show modal
          navigate('/');
          alert('Access denied.');
          //setModalOpen(true);
          
          return;
        }

        try {
          // Decode the JWT to get the role
          const decodedToken = jwtDecode(token);
          const { sub: { role } } = decodedToken; // Corrected way to extract role

          // Check if the user has the admin role
          if (role !== 'admin') {
            // If not admin, redirect or show an alert
            alert('Access denied.');
            navigate('/');
          }
        } catch (error) {
          console.error('Error decoding JWT:', error);
          navigate('/login');
        }
      };

      checkAuth();
    }, [navigate]);

    return (
      <>
        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
          <Modal.Header closeButton>
            <h2>Access Denied</h2>
          </Modal.Header>
          <Modal.Body>
            <p>You are not authorized to access this page!</p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setModalOpen(false)}>Close</button>
          </Modal.Footer>
        </Modal>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withAdminProtection;
