import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './CourseDetails.css'; // Import styles
import CustomPDFViewer from './CustomPDFViewer'; // Import your custom PDF viewer

const CourseDetails = () => {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        //const response = await axios.get(`http://localhost:5000/api/courses/${courseId}`);
        const response = await axios.get(`/api/courses/${courseId}`);
        setCourseDetails(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const fetchCourseVideo = async () => {
      try {
        //const videoResponse = await axios.get(`http://localhost:5000/api/courses/${courseId}/video`);
        const videoResponse = await axios.get(`/api/courses/${courseId}/video`);
        
        setVideoUrl(videoResponse.data.video_url);
        setShowVideoPopup(true); // Show the video popup if a video exists
      } catch (error) {
        console.log('No video associated with this course.');
      }
    };

    fetchCourseDetails();
    fetchCourseVideo();
  }, [courseId]);

  const handleShare = () => {
    const shareUrl = window.location.href; // Current course URL
    if (navigator.share) {
      navigator.share({
        title: courseDetails.title,
        text: courseDetails.description,
        url: shareUrl,
      }).then(() => {
        console.log('Course shared successfully');
      }).catch((error) => {
        console.error('Error sharing course:', error);
      });
    } else {
      // Fallback for browsers that don't support the Web Share API
      alert(`Copy this link to share: ${shareUrl}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading course details.</div>;
  }

  const renderContent = () => {
    const filePath = `https://wasomihuru.co.tz/${courseDetails.file_path.replace(/\\/g, '/')}`;
    //const filePath = `${courseDetails.file_path.replace(/\\/g, '/')}`;
    //console.log(filePath);
    //replace with domain

    if (courseDetails.file_path.endsWith('.pdf')) {
      return <CustomPDFViewer pdfUrl={filePath} />;
    } else if (
      courseDetails.file_path.endsWith('.docx') ||
      courseDetails.file_path.endsWith('.doc')
    ) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
          width="100%"
          height="600px"
          frameBorder="0"
        />
      );
    } else {
      return (
        <div
          className="course-content"
          dangerouslySetInnerHTML={{ __html: courseDetails.content }}
        />
      );
    }
  };

  return (
    <div className="course-page">
      <div className="course-header">
        <h2>{courseDetails.title}</h2>
        <button className="share-button" onClick={handleShare}>Share</button>
      </div>
      <p>{courseDetails.description}</p>
      {renderContent()}

      {showVideoPopup && videoUrl && (
        <div className="video-popup">
          <video src={videoUrl} controls autoPlay className="course-video" />
          <button onClick={() => setShowVideoPopup(false)} className="close-button">X</button>
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
