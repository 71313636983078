// src/components/AdminDashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './AdminPage.css'; // Import styles
import withAdminProtection from '../withAdminProtection'; // Adjust the path as necessary

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      <nav>
        <ul>
          <li><Link to="/admin/create-course" className=' admin-link'>Create Course</Link></li>
          <li><Link to="/admin/manage-courses" className='admin-link'>Manage Courses</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default withAdminProtection(AdminDashboard);
