// src/App.js
import React, { useEffect,useRef,  useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navibar from './components/navbar';
import { AuthProvider } from './AuthContext';
import Hero from './components/hero';
import ChatRoom from './components/ChatRoom';
import PopularTopics from './components/PopularTopics';
import StatsSection from './components/StatsSection';
import FeedbackSection from './components/FeedbackSection';
import ReachOutSection from './components/ReachOutSection';
import Footer_ from './components/Footer';
import CallToActionSection from './components/CallToActionSection';
import BrowseCourses from './components/BrowseCourses';
import CourseDetails from './components/CourseDetails'; // Import the CourseDetails component
import AdminPage from './components/AdminPage'; // Import the AdminPage component
import CreateCourse from './components/CreateCourse'; // Import the CreateCourse component
import ManageCourses from './components/ManageCourses'; // Import the ManageCourses component
import EditCourse from './components/EditCourse';
import Register from './components/Register';
import Login from './components/Login';
import ProtectedRoute from './ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';


//phone number on footer
//logo

function App() {

  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));

  return (
    <Router>
      <div className="App">
        <Navibar authToken={authToken} />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <PopularTopics />
              <StatsSection />
              <FeedbackSection />
              <CallToActionSection />  {/* Added CTA Section here */}
              <ReachOutSection />
            </>
          } />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login setAuthToken={setAuthToken} />} />
          <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <ChatRoom />
            </ProtectedRoute>
          }
        />
          <Route path="/browse-courses" element={<BrowseCourses />} /> {/* Add new route for BrowseCourses */}
          <Route path="/courses/:courseId" element={<CourseDetails />} /> {/* Add route for CourseDetails */}
          <Route path="/admin" element={<AdminPage />} /> {/* Add route for AdminPage */}
          <Route path="/admin/create-course" element={<CreateCourse />} /> {/* Add route for CreateCourse */}
          <Route path="/admin/manage-courses" element={<ManageCourses />} /> {/* Add route for ManageCourses */}
          <Route path="/admin/edit-course/:courseId" element={<EditCourse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer_ />
      </div>
    </Router>
  );
}

export default App;
