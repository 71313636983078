import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Login.css'; // Ensure this CSS file exists

const Login = ({ setAuthToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false); // State to manage success alert visibility
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors
  
    // Basic client-side validation
    if (!username || !password) {
      setError('Username and password are required');
      return;
    }
  
    // Sanitize input (though this is not a substitute for server-side validation)
    const sanitizedUsername = username.trim();
    const sanitizedPassword = password.trim();
  
    try {
      //const response = await fetch('http://localhost:5000/api/login'
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',  // Important for sending cookies
        body: JSON.stringify({ username: sanitizedUsername, password: sanitizedPassword }),
      });
  
      const data = await response.json();
      console.log(data);
  
      if (response.ok) {
        login(); // Set isAuthenticated to true
        const from = location.state?.from?.pathname || '/';
        setSuccess(true);
        setTimeout(() => {
          navigate(from);
        }, 1000); // Redirect after 1 second
      } else {
        setError(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };
  

  return (
    <div className="login-container my-4">
      <h2 className="login-title">Login</h2>
      {error && <p className="error-message">{error}</p>}
      {success && (
        <div className="alert alert-success" role="alert">
          Login successful! Redirecting...
        </div>
      )}
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
          className="login-input"
          maxLength="50" // Limit username length
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="login-input"
          maxLength="100" // Limit password length
        />
        <button type="submit" className="login-button">Login</button>
      </form>
      <p className="register-link">
        Not registered? <a href="/register">Create an account</a>
      </p>
    </div>
  );
};

export default Login;
