// src/components/PopularTopics.js
import React, { useState, useEffect } from 'react';
import './PopularTopics.css'; // Import the styles

const topics = [
  { title: 'Technology', description: 'Explore the latest in technology.', icon: 'tech-icon' },
  { title: 'Language', description: 'Learn new languages.', icon: 'language-icon' },
  { title: 'Science', description: 'Discover scientific wonders.', icon: 'science-icon' },
  { title: 'Health', description: 'Stay informed about health.', icon: 'health-icon' }
];

const PopularTopics = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % topics.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isMobile]);

  return (
    <div className="popular-topics">
      <h2 className="popular-topics-title">Popular Topics</h2>
      <div className="cards-container">
        {topics.map((topic, index) => (
          <div
            className="card"
            key={index}
            style={{
              display: isMobile ? (index === currentIndex ? 'block' : 'none') : 'block'
            }}
          >
            <div className={topic.icon}></div>
            <h3 className="card-title">{topic.title}</h3>
            <p className="card-description">{topic.description}</p>
          </div>
        ))}
      </div>
      {isMobile && (
        <div className="pagination-dots">
          {topics.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PopularTopics;
