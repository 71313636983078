import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';  // This is crucial for Webpack integration
import 'pdfjs-dist/web/pdf_viewer.css';
import './CustomPDFViewer.css';

// Set the worker source for PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const CustomPDFViewer = ({ pdfUrl }) => {
  const pdfViewerRef = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    loadingTask.promise.then((pdfDoc) => {
      setPdf(pdfDoc);
      setNumPages(pdfDoc.numPages);
      renderPage(pdfDoc, currentPage);
    }).catch(error => {
      console.error('Error loading PDF:', error);
    });
  }, [pdfUrl, currentPage]);

  const renderPage = (pdfDoc, pageNum) => {
    pdfDoc.getPage(pageNum).then((page) => {
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      const canvas = pdfViewerRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext);
    });
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div className="pdf-viewer-container">
      <div className="pdf-controls">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous Page
        </button>
        <span>{`Page ${currentPage} of ${numPages}`}</span>
        <button onClick={goToNextPage} disabled={currentPage === numPages}>
          Next Page
        </button>
      </div>
      <canvas ref={pdfViewerRef} className="pdf-canvas"></canvas>
    </div>
  );
};

export default CustomPDFViewer;
