import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './EditCourse.css'; // Import styles
import withAdminProtection from '../withAdminProtection'; // Adjust the path as necessary

const EditCourse = () => {
  const { courseId } = useParams();
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseClass, setCourseClass] = useState('');
  const [courseSubject, setCourseSubject] = useState('');
  const [courseFile, setCourseFile] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        //const response = await fetch(`http://127.0.0.1:5000/api/courses/${courseId}`);
        const response = await fetch(`/api/courses/${courseId}`);
        const data = await response.json();
        setCourseTitle(data.title);
        setCourseDescription(data.description);
        setCourseClass(data.course_class);
        setCourseSubject(data.course_subject);
        // Note: course content file isn't loaded here since it's stored in the filesystem
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchCourseDetails();
  }, [courseId]);

  const handleFileChange = (e) => {
    setCourseFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('courseTitle', courseTitle);
    formData.append('courseDescription', courseDescription);
    formData.append('courseClass', courseClass);
    formData.append('courseSubject', courseSubject);
    
    if (courseFile) {
      formData.append('courseFile', courseFile);
    }

    try {
      /*const response = await fetch(`http://127.0.0.1:5000/api/courses/${courseId}`, {
        method: 'PUT',
        body: formData,
      });
      */
      const response = await fetch(`/api/courses/${courseId}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        console.log('Course updated successfully');
      } else {
        console.error('Failed to update course');
      }
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  return (
    <div className="edit-course">
      <h2>Edit Course</h2>
      <form onSubmit={handleSubmit} className="edit-course-form">
        <div className="form-group">
          <label htmlFor="courseTitle">Course Title</label>
          <input
            type="text"
            id="courseTitle"
            value={courseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="courseDescription">Course Description</label>
          <textarea
            id="courseDescription"
            value={courseDescription}
            onChange={(e) => setCourseDescription(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="courseClass">Class</label>
          <select
            id="courseClass"
            value={courseClass}
            onChange={(e) => setCourseClass(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select Class</option>
            <option value="class1">Class 1</option>
            <option value="class2">Class 2</option>
            <option value="class3">Class 3</option>
            {/* Add more classes as needed */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="courseSubject">Subject</label>
          <select
            id="courseSubject"
            value={courseSubject}
            onChange={(e) => setCourseSubject(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select Subject</option>
            <option value="math">Math</option>
            <option value="science">Science</option>
            <option value="history">History</option>
            {/* Add more subjects as needed */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="courseFile">Course Content File</label>
          <input
            type="file"
            id="courseFile"
            onChange={handleFileChange}
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Course</button>
      </form>
    </div>
  );
};

export default withAdminProtection(EditCourse);
