// src/components/PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.css'; // Import styles
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Effective Date: 30/08/2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Wasomi Huru Tz. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy or our practices with regards to your personal information, please contact us at <span><a className = "nav-link" href={'/'}>email</a></span>
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, when you participate in activities on the website (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
        </p>
        <p>
          The personal information we collect may include your name, email address, contact information, and any other details you choose to provide.
        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>
          We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
        </p>
        <ul>
          <li>To manage user accounts</li>
          <li>To send administrative information</li>
          <li>To protect our services</li>
          <li>To enforce our terms, conditions, and policies</li>
          <li>To respond to legal requests and prevent harm</li>
          <li>To deliver and facilitate the delivery of services to the user</li>
        </ul>
      </section>

      <section>
        <h2>4. Intellectual Property Rights</h2>
        <p>
          All content on this website, including but not limited to text, graphics, images, and videos, are the property of Wasomi Huru Tz or its content creators and are protected by copyright and other intellectual property laws.
        </p>
        <p>
          You may not copy, reproduce, distribute, or create derivative works from any content on this website without the express permission of [Your E-Learning Website Name] or the respective copyright owner.
        </p>
      </section>

      <section>
        <h2>5. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or the content of such third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.
        </p>
      </section>

      <section>
        <h2>6. Cookies and Tracking Technologies</h2>
        <p>
          We may use cookies and similar tracking technologies to access or store information. Cookies are small files stored on your device that allow us to improve your experience on our website. You can set your browser to refuse all cookies or to indicate when a cookie is being sent.
        </p>
      </section>

      <section>
        <h2>7. Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </p>
      </section>

      <section>
        <h2>8. Limitation of Liability</h2>
        <p>
          Wasomi Huru Tz assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness, or timeliness.
        </p>
        <p>
          You agree that your use of our website and services is at your sole risk. [Your E-Learning Website Name] will not be liable for any damages of any kind resulting from the use of this website or its content.
        </p>
      </section>

      <section>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We encourage you to review this privacy policy periodically to stay informed about how we are protecting your information.
        </p>
      </section>

      <section>
        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns about this privacy policy, please contact us.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
