// src/components/StatsSection.js
import React from 'react';
import './StatsSection.css'; // Import the styles

const stats = [
  { number: '400', label: 'Courses' },
  { number: '50,000', label: 'Students' },
  { number: '200', label: 'Instructors' }
];

const StatsSection = () => {
  return (
    <div className="stats-section">
      <div className="container">
        <div className="row">
          {stats.map((stat, index) => (
            <div className="col-12 col-md-4 stat-item" key={index}>
              <div className="stat-number">{stat.number}</div>
              <div className="stat-label">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
