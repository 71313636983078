// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Import the styles

const Footer_ = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-section">
            <h5>About Us</h5>
            <p>
              We are a leading e-learning platform providing top-notch education resources for students worldwide.
            </p>
          </div>
          <div className="col-md-4 footer-section">
            <h5>Quick Links</h5>
            <ul className="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="#about" disabled >About</a></li>
              <li><a href="/browse-courses">Courses</a></li>
              <li><a href="/privacy-policy">Privacy policy</a></li>
              <li><a href="/admin">Admin</a></li>
            </ul>
          </div>
          <div className="col-md-4 footer-section">
            <h5>Contact Us</h5>
            <p>Email: info@wasomihuru.co.tz</p>
            <p>Phone: NA</p>
            <div className="social-icons">
              <a href="https://www.facebook.com"><i className="fab fa-facebook-f"></i></a>
              <a href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
              <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com"><i className="fab fa-linkedin"></i></a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <p className="footer-bottom">
              &copy; {new Date().getFullYear()} wasomihuru Platform. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_;
