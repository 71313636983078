import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const BrowseCourses = () => {
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10; // Max items per page
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch initial subjects
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    if (!hasMore) return; // Prevent further requests if no more data

    try {
      const response = await axios.get(`/api/listsubjects?page=${page}&limit=${limit}`);
      setSubjects((prevSubjects) => [...prevSubjects, ...response.data.subjects]);
      setHasMore(response.data.has_more);
      setPage((prevPage) => prevPage + 1); // Increment the page for the next request
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      // Trigger loading more subjects if the user reaches the bottom of the page
      fetchSubjects();
    }
  };

  useEffect(() => {
    // Add event listener for infinite scrolling
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]); // Run this when `hasMore` changes

  return (
    <div className="browse-courses">
      <h2>Browse Courses</h2>
      <ul className="subject-list">
        {subjects.map((subject) => (
          <li key={subject.id} onClick={() => navigate(`/subject/${subject.id}`)}>
            <h3>{subject.name}</h3>
            <p>{subject.description}</p>
          </li>
        ))}
      </ul>
      {!hasMore && <p>No more subjects to display.</p>}
    </div>
  );
};

export default BrowseCourses;

/*import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BrowseCourses.css'; // Import styles

const BrowseCourses = () => {
  const [classLevel, setClassLevel] = useState('');
  const [subject, setSubject] = useState('');
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleClassChange = (e) => {
    setClassLevel(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setCourses([]);

    try {
      
      const response = await fetch(`/api/courses?class=${classLevel}&subject=${subject}`);
        //const response = await fetch(`http://localhost:5000/api/courses?class=${classLevel}&subject=${subject}`);
        const contentType = response.headers.get("content-type");

        if (response.ok && contentType && contentType.includes("application/json")) {
            const data = await response.json();
            setCourses(data);
        } else {
            const errorText = await response.text();
            console.error('Failed to fetch courses:', errorText);
            setError('Failed to fetch courses. Please check the server response.');
        }
    } catch (error) {
        console.error('Error:', error);
        setError('Error: ' + error.message);
    }

    setLoading(false);
};

  return (
    <div className="browse-courses">
      <h2>Select Your Class and Subject</h2>
      <form onSubmit={handleSubmit} className="browse-form">
        <div className="form-group">
          <label htmlFor="classLevel">Class Level</label>
          <select id="classLevel" value={classLevel} onChange={handleClassChange} className="form-control" required>
            <option value="" disabled>Select your class</option>
            <option value="class1">Class 1</option>
            <option value="class2">Class 2</option>
            <option value="class3">Class 3</option>

          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <select id="subject" value={subject} onChange={handleSubjectChange} className="form-control" required>
            <option value="" disabled>Select your subject</option>
            <option value="math">Mathematics</option>
            <option value="science">Science</option>
            <option value="history">History</option>

          </select>
        </div>
        <button type="submit" className="btn btn-primary">Load Courses</button>
      </form>
      <div className="courses-list">
        {loading && <p>Loading courses...</p>}
        {error && <p className="error-message">{error}</p>}
        {courses.length > 0 && (
          <div>
            <h3>Available Courses</h3>
            <ul>
              {courses.map(course => (
                <li key={course.id} className="course-item">
                  <Link to={`/courses/${course.id}`} className="course-link">
                    <h4>{course.title}</h4>
                    <p>{course.description}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrowseCourses; */
