// src/components/FeedbackSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import './FeedbackSection.css'; // Import the styles
import avatar1 from '../images/dre.jpg'; // Import the images directly
import avatar2 from '../images/dre.jpg';
import avatar3 from '../images/dre.jpg';

const feedbacks = [
  {
    name: 'John Mushi',
    comment: 'This platform has transformed the way I learn. The courses are well-structured and easy to follow.',
    avatar: avatar1 // Add your own avatar images in the src/images directory
  },
  {
    name: 'Hamida Salehe',
    comment: 'I love the community feature. It allows me to connect with fellow students and share knowledge.',
    avatar: avatar2
  },
  {
    name: 'Mike Johnson',
    comment: 'The instructors are knowledgeable and the content is up-to-date. Highly recommend!',
    avatar: avatar3
  }
];

const FeedbackSection = () => {
  return (
    <div className="feedback-section">
      <div className="container">
        <h2 className="section-title">What Our Readers Have to Say</h2>
        <div className="row">
          {feedbacks.map((feedback, index) => (
            <div className="col-12 col-md-4 feedback-item" key={index}>
              <div className="feedback-avatar">
                <img src={avatar1} alt={`${feedback.name}'s avatar`} />
              </div>
              <div className="feedback-comment">"{feedback.comment}"</div>
              <div className="feedback-name">- {feedback.name}</div>
            </div>
          ))}
        </div>
        <h4><Link className="nav-link" to="/chat">You can also reach out to our <span className='spanlink'>Community</span> and socialise</Link></h4>
      </div>
    </div>
  );
};

export default FeedbackSection;
