import React, { useState, useEffect } from 'react';
import './HeroSection.css'; // Import the styles
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar'; // Import the SearchBar component

const Hero = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the list of courses from the backend
    const fetchCourses = async () => {
      try {
        const response = await fetch('/api/allcourses');
        //const response = await fetch('http://localhost:5000/api/allcourses'); // Replace with your actual API endpoint
        const data = await response.json();
        console.log('Fetched courses:', data); // Log the data
        setCourses(data); // Set the fetched courses to state
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []); // Empty dependency array to run this effect only once when the component mounts

  const handleBrowseCourses = () => {
    navigate('/browse-courses');
    //for the page of this button link I need a new backend flask api that is going to return all the subjects, and display them in like a list or something keeping them clickable
    //also like there should be a max number of iterms that can be displayed on the screen and like load more when the user reaches the bottom of the page
    //your experiance and experties to deliver and improve this idea of mine would be appreciated  
  };

  const handleSearchSelect = (courseTitle) => {
    console.log(`Selected course: ${courseTitle}`);
    // Navigate to the course page based on the course title or adjust as needed
    navigate(`/courses/${courseTitle}`);
  };

  return (
    <>
      <div className="hero-section">
        <div className="px-4 py-5 text-center">
          <h1 className="display-5 fw-bold">
            <span className="pop">Learn</span> on your schedule
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Anywhere, anytime, Start learning today 📅⏱️
            </p>
            <SearchBar options={courses} onSelect={handleSearchSelect} />
            <button
              onClick={handleBrowseCourses}
              className="btn btn-primary my-3 cta-button"
            >
              Browse all Courses
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
